var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showLogin, width: "430px", "show-close": false }
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("h2", { staticStyle: { "margin-bottom": "30px" } }, [
                _vm._v("欢迎登录")
              ]),
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  staticClass: "login-form",
                  attrs: {
                    model: _vm.loginForm,
                    rules: _vm.loginRules,
                    "label-position": "left"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "f-item", attrs: { prop: "phone_number" } },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          class: {
                            active:
                              _vm.focusedField == "phone_number" ||
                              _vm.loginForm.phone_number != ""
                          },
                          attrs: { for: "phone_number" }
                        },
                        [_vm._v("手机号")]
                      ),
                      _c("el-input", {
                        attrs: {
                          id: "phone_number",
                          autocomplete: "on",
                          name: "phone_number"
                        },
                        on: { focus: _vm.focus },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.login.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.loginForm.phone_number,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "phone_number", $$v)
                          },
                          expression: "loginForm.phone_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "f-item", attrs: { prop: "password" } },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          class: {
                            active:
                              _vm.focusedField == "login-pass" ||
                              _vm.loginForm.password != ""
                          },
                          attrs: { for: "phone_number" }
                        },
                        [_vm._v("密码")]
                      ),
                      _c("el-input", {
                        key: "password",
                        attrs: { id: "login-pass", type: "password" },
                        on: { focus: _vm.focus },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.login.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: {
                        id: "login-button",
                        loading: _vm.loading,
                        type: "primary"
                      },
                      on: { click: _vm.login }
                    },
                    [_vm._v("登录")]
                  ),
                  _c("div", { staticClass: "revoke" }, [
                    _c("a", { on: { click: _vm.cancelLogin } }, [
                      _vm._v("取消")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }