import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import router from './router'
import {ApiPromise} from "@/api/api-promise"
import 'element-ui/lib/theme-chalk/index.css'
import filters from '@/filters'
import store from './store'
import vueAmap from 'vue-amap'
import SvgIcon from '@/views/IconSvg'

const req = require.context('@/icons', true, /\.svg$/)
req.keys().map(req)

Vue.component('svg-icon', SvgIcon)
Vue.config.productionTip = false

//安装组件
Vue.use(ElementUI)
Vue.use(vueAmap)

vueAmap.initAMapApiLoader({
  key: '338d85b1aec6abb0ea98da78857fc5bb',
  plugin: ['Autocomplete', 'PlaceSearch', 'Scale', 'OverView', 'ToolBar', 'MapType', 'PolyEditor', 'AMap.CircleEditor', 'AMap.Marker'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
// Vue.prototype.$url_config = {
//    VUE_APP_URL_PROOF:process.env.VUE_APP_URL_PROOF,
//    VUE_APP_URL_SEARCH:process.env.VUE_APP_URL_SEARCH,
//    VUE_APP_URL_ELITE:process.env.VUE_APP_URL_ELITE,
//    VUE_APP_URL_ASSISTANT:process.env.VUE_APP_URL_ASSISTANT,
//    VUE_APP_URL_DELEGATE:process.env.VUE_APP_URL_DELEGATE,
//    VUE_APP_URL_PROOF_INRO:process.env.VUE_APP_URL_PROOF_INRO
// }

// Set the default API error handler to use element ui message
ApiPromise.defaultOnReject = (err) => {
  ElementUI.Message.error(err.toString());
};
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.mixin({
  beforeCreate() {
     const options = this.$options;

     this.$hm = options.parent && options.parent.$hm
        ? options.parent.$hm
        : (function (window) {
           if (!window._hmt) {
              return;
           }
           return Object.assign({}, window._hmt, { // 统计页面的点击事件
              click: (category, val) => {
                 const userInfo = store.getters.userInfo || {};
                 return window._hmt.push([
                    '_trackEvent',
                    category,
                    'click',
                    userInfo.loginName || 'notLogin',
                    val
                 ]);
              }
           });
        })(window);
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
