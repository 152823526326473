
import axios from 'axios';
import {ApiPromise} from "@/api/api-promise";
import JSONbig from 'json-bigint';

let versionPrefix = "v1";
let baseUrl = process.env.VUE_APP_BASE_API + "/" + versionPrefix;
let jsonBig = JSONbig({ useNativeBigInt: true });
let httpClient = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
    transformResponse: [
        function (data) {
            if (!data) {
                return {};
            }
            return jsonBig.parse(data);
        },
    ],
    transformRequest: [
        function (data, headers) {
            headers['Content-Type'] = 'application/json';
            return jsonBig.stringify(data);
        },
    ],
});

httpClient.interceptors.response.use(
    (response) => {
        if(response.config.responseType === 'blob') {
            return Promise.resolve(response.data);
        } else if(response.status === 200) {
            // Normal response
            return Promise.resolve(response.data.data);

        } else if (response.status === 400) {
            if (response.data.filed_name === 'user_login') {
                window.location.reload()
            }
            else if (response.data.filed_name === 'vault_login') {
                window.location.reload()
            }
            // Validation error
            return Promise.reject(response.data);
        } else {
            // Exceptions
            return Promise.reject("server exception");
        }
    },
    (error) => {
        return Promise.reject(error);
    });

function post(url, data, config) {
    return new ApiPromise(httpClient.post(url, data, config));
}

function get(url, config) {
    return new ApiPromise(httpClient.get(url, config));
}

function put(url, data, config) {
    return new ApiPromise(httpClient.put(url, data, config));
}

function del(url, config) {
    return new ApiPromise(httpClient.delete(url, config));
}

export default { httpClient, post, get, put, del };
