const SET_ACTIVEINDEX = 'SET_ACTIVEINDEX'

const state = {
    activeIndex: '1',
}

const mutations = {
    [SET_ACTIVEINDEX](state, payload) {
        Object.assign(state, payload)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
}