// const SET_POSITION = 'SET_POSITION'
const SET_POSITION_TOP = 'SET_POSITION_TOP'
const SET_POSITION_BOTTOM = 'SET_POSITION_BOTTOM'
const SET_ACTIVEINDEX = 'SET_ACTIVEINDEX'

const state = {
    menuTop: true,
    activeIndex: '1',
}

const mutations = {
    [SET_ACTIVEINDEX](state, payload) {
        Object.assign(state, payload)
    },
    [SET_POSITION_TOP](state) {
        state.menuTop = true
    },
    [SET_POSITION_BOTTOM](state) {
        state.menuTop = false
    },
}

export default {
    namespaced: true,
    state,
    mutations,
}