var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", _vm._g({ class: _vm.svgClass }, _vm.$listeners), [
    _c("use", { attrs: { "xlink:href": _vm.iconName } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }