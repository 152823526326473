<template>
   <div id="app">
      <router-view />
      <el-dialog :visible="showLogin" width="430px" :show-close="false" >
         <div class="content">
            <h2 style="margin-bottom:30px">欢迎登录</h2>
            <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" label-position="left"
               @submit.native.prevent>
               <el-form-item class="f-item" prop="phone_number">
                  <label class="label"
                     :class="{active:focusedField == 'phone_number' || loginForm.phone_number != ''}"
                     for="phone_number">手机号</label>
                  <el-input id="phone_number" v-model="loginForm.phone_number" autocomplete="on"
                     name="phone_number" @keyup.enter.native="login" @focus="focus" />
               </el-form-item>
               <el-form-item class="f-item" prop="password">
                  <label class="label" :class="{active:focusedField == 'login-pass' || loginForm.password != ''}"
                     for="phone_number">密码</label>
                  <el-input id="login-pass" key="password" v-model="loginForm.password" type="password"
                     @keyup.enter.native="login" @focus="focus" />
               </el-form-item>
               <el-button id="login-button" :loading="loading" type="primary" @click="login" class="btn">登录</el-button>
               <div class="revoke">
                  <a @click="cancelLogin">取消</a>
               </div>
            </el-form>
         </div>
      </el-dialog>
   </div>
</template>

<script>
import NewsAPI from '@/api/v1/news.js'
import {Message} from 'element-ui'
import { mapState } from 'vuex'

export default {
   name: "App",
   computed:{
      ...mapState({showLogin:(state)=>state.system.login.show})
   },
   data() {
      return {
        loading: false,
        typeValue: "jwt",
         loginForm: {
            password: "",
            phone_number: "",
         },
         loginRules: {
            password: [
               { required: true, trigger: "blur", message: `请输入密码` },
            ],
           phone_number: [
               { required: true, trigger: "blur", message: `请输入手机号` },
            ],
         },
         focusedField: "",
      };
   },
   methods: {
      focus(event) {
         this.focusedField = event.target.id;
      },
      cancelLogin(){
         let login = this.$store.state.system.login
         if(login.reject){
            login.reject();
            login.resolve = login.reject = null
            login.show = false
            this.$store.commit("system/SET_LOGIN",login);
         }
      },
      login(){
         this.$refs.loginForm.validate((valid)=>{
            if(valid) {
               let digest = this.loginForm.password
               NewsAPI.ssoLogin(this.loginForm.phone_number,digest).then((res)=>{
                 debugger
                  this.$store.commit("system/SET_TOKEN", res.token);
                 this.$store.commit("system/SET_TYPE", this.typeValue);
                  Message.success('登录成功');
                  window.name = JSON.stringify({ token: res.token });
                  let login = this.$store.state.system.login
                  login.show = false
                  if(login.resolve) {
                     login.resolve(res.Token)
                  }
                  login.resolve = login.reject = null
                  this.$store.commit("system/SET_LOGIN",login);
               }).catch((e)=>{
                  Message.error('用户名或密码不正确');
               })
            }
         })
      }
   },
   mounted() {
      if (
         window.location.search &&
         window.location.search.match(/^.*[?|&]token=([^&]*).*$/g)
      ) {
         let token = window.location.search.match(
            /^.*[?|&]token=([^&]*).*$/
         )[1];
         this.$store.commit("system/SET_TOKEN", token);
         window.name = JSON.stringify({ token: token });
      } else if (window.name && window.name != "") {
         try {
            let token = JSON.parse(window.name).token;
            this.$store.commit("system/SET_TOKEN", token);
           this.$store.commit("system/SET_TYPE", this.typeValue);
         } catch (e) {
            console.log(e);
         }
      }
   },
};
</script>

<style>
#app {
   /* font-family: Avenir, Helvetica, Arial, sans-serif; */
   font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}
</style>

<style scoped lang="stylus">
::v-deep .el-input {
   input {
      color: black;
   }

   textarea {
      color: black;
   }
}

/deep/.el-dialog {
   border-radius 12px
}
/deep/.el-input__inner {
   padding: 0px;
   border: none;
   border-bottom: 1px solid #143654;
   border-radius: 0px;
}

.content {
   height: 390px;
   padding: 25px 65px 77px 65px;
   box-sizing: border-box;
   h2 {
      font-size: 28px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #222222;
      line-height: 40px;
   }
   .f-item {
      margin-bottom: 31px;

      .label {
         position: absolute;
         top: 0px;
         right: auto;
         z-index: 3;
         transition: 0.2s all ease;
         transform-origin: left;
         pointer-events: none;
         color: #999;

         &.active {
            transform: scale(0.8);
            top: -30px;
         }
      }
   }

   .btn {
      margin-top: 15px;
      width: 100%;
      height: 44px;
      background: #2C53B0;
      border-radius: 2px;
      margin-bottom: 9px;
      font-size: 18px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #FFFFFF;
   }

   .revoke {
      text-align: center;
      font-size: 18px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #B3B3B3;
      line-height: 44px;

      a {
         cursor: pointer;
      }
   }
}
</style>
