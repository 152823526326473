import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const index = () => import("@/views/index/index.vue");
const home = () => import("@/views/index/home/index.vue");
const dashboard = () => import("@/views/dashboard/index.vue");
const newsList = () => import("@/views/dashboard/news-list/index.vue");
const articleDetail = () =>
  import("@/views/dashboard/article-detail/index.vue");
const districtIntro = () =>
  import("@/views/dashboard/district-intro/index.vue");
const proofIntro = () => import("@/views/dashboard/proof-intro/index.vue");
const contactUs = () => import("@/views/dashboard/contact-us/index.vue");
const service = () => import("@/views/dashboard/service/index.vue");
const serviceCenterIntro = () =>
  import("@/views/dashboard/service/center-intro.vue");
const serviceApply = () => import("@/views/dashboard/service/apply.vue");
const serviceInfo = () => import("@/views/dashboard/service/info.vue");
const serviceHelp = () => import("@/views/dashboard/service/help.vue");
const serviceOthers = () => import("@/views/dashboard/service/others.vue");

const business = () => import("@/views/dashboard/business/index.vue");
const businessPatent = () => import("@/views/dashboard/business/patent.vue");
const businessTrademark = () =>
  import("@/views/dashboard/business/trademark.vue");
const businessOthers = () => import("@/views/dashboard/business/others.vue");

export const routes = [
  {
    path: "/",
    component: index,
    children: [
      {
        path: "",
        component: home,
        name: "home",
      },
      {
        path: "hndip",
        component: dashboard,
        children: [
          {
            path: "news-list/:type",
            component: newsList,
            name: "newsList",
          },
          {
            path: "/district-intro",
            component: districtIntro,
            name: "districtIntro",
          },
          {
            path: "/proof-intro",
            component: proofIntro,
            name: "proofIntro",
          },
          {
            path: "/contact-us",
            component: contactUs,
            name: "contactUs",
          },
          {
            path: "/article-detail/:from/:id",
            component: articleDetail,
            name: "articleDetail",
          },
          {
            path: "/service",
            component: service,
            name: "service",
            children: [
              {
                path: "center-intro",
                component: serviceCenterIntro,
                name: "serviceCenterIntro",
              },
              {
                path: "apply",
                component: serviceApply,
                name: "serviceApply",
              },
              {
                path: "info",
                component: serviceInfo,
                name: "serviceInfo",
              },
              {
                path: "help",
                component: serviceHelp,
                name: "serviceHelp",
              },
              {
                path: "others",
                component: serviceOthers,
                name: "serviceOthers",
              },
            ],
          },
          {
            path: "/business",
            component: business,
            name: "business",
            children: [
              {
                path: "patent",
                component: businessPatent,
                name: "businessPatent",
              },
              {
                path: "trademark",
                component: businessTrademark,
                name: "businessTrademark",
              },
              {
                path: "others",
                component: businessOthers,
                name: "businessOthers",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      var warper = document.getElementById("app-warper");
      warper.scroll(0, 0);
      return { x: 0, y: 0 }; // return的结果, 就是 期望滚动到哪个的位置
    }
  },
  routes,
});

router.afterEach((to, from) => {
  // 每次进入路由都会触发
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(["_setAutoPageview", false]);
      window._hmt.push([
        "_trackPageview",
        "/template/displayTemplate/dist/index.html#" + to.fullPath,
      ]); // 如果不是根路径，需要指定第二个参数的路径
    }
  }
});

export default router;
