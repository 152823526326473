import v1 from './v1';

export default {
    getList(page, pageSize, isImageBanner, isTitleBanner, type) {
        var para = "?"
        if (page >= 0) {
            para = para + 'page_id=' + page 
        }
        else {
            para = 'page_id=0'
        }
        if (pageSize) {
            para = para + '&page_size=' + pageSize 
        }
        if (isImageBanner) {
            para = para + '&is_image_banner=' + isImageBanner 
        }
        if (isTitleBanner) {
            para = para + '&is_title_banner=' + isTitleBanner 
        }
        if (type) {
            para = para + '&type=' + type 
        }
        return v1.get('/op/news/articles' + para)
    },
    getImageBanner() {
        return this.getList(0, 10, true)
    },
    getTitleBanner() {
        return this.getList(0, 10, null, true)
    }, 
    getNewsDetail(articleId) {
        return v1.get('/op/news/articles/' + articleId)
    },
    publishNews(title, abstract, main_figure_key, external_link, source, content, publish_time, sort) {
        let data = {
            title: title,
            abstract: abstract, 
            main_figure_key: main_figure_key,
            external_link: external_link,
            source: source, 
            content: content, 
            publish_time: publish_time, 
            type: sort
        }
        return v1.post('/op/news/articles', data)
    },
    ssoLogin(account,password) {
      return v1.post('/op/sso/users/tokens', {phonenumber: account, password: password})
    }
};