// const SET_POSITION = 'SET_POSITION'
const SET_TOKEN = 'SET_TOKEN'
const SET_TYPE = 'SET_TYPE'
const SET_LOGIN = 'SET_LOGIN'

const state = {
   token:null,
   login:{
      show:false,
      resolve:null,
      reject:null,
       type: null,
   }
}

const mutations = {
  [SET_TOKEN](state, payload) {
      state.token = payload
  },
    [SET_TYPE](state, payload) {
        state.type = payload
    },
  [SET_LOGIN](state, payload) {
   state.login = payload
},
}

export default {
    namespaced: true,
    state,
    mutations,
}