
import format from 'date-fns/format'

const filters = {
  second2MonthDay: (sec) => {
    return format(sec * 1000, 'MM月dd日')
  },
  second2Date: (sec) => {
    if (sec === undefined) {
      return null
    }
    return format(sec * 1000, 'yyyy-MM-dd HH:mm:ss')
  },
  second2YMD: (sec) => {
    return format(sec * 1000, 'yyyy-MM-dd')
  },
}

export default filters
